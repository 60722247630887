import React from 'react';
import bg from '../../../../../Assets/Images/Wallpaper1.jpg';

const Silicone=()=> {
return (
  <div classNameName="Pages-font-size">
  <h2 className="text-center">SILICONE PRODUCT</h2>
<div className="row container-fluid justify-content-center mt-3">
  <div className="col-sm-4 ">
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  <img className="w-100 rounded mt-2" src={bg}alt=""/>
  </div>
  <div className="col-sm-8  mt-2 mb-5">
      <p>We are one of the best fabricators of most life like Silicone prosthesis. Where optimization of aesthesis is being combined with function Providing ultimate Patient Satisfaction. Our highly efficient and skilled team comprising die-hard prosthetist, Maxillofacial Prosthodontist, Anaplastologist, Ocularist under continuous guidance of Orthopedic and Plastic Surgeons, doing their best for optimum result. Our day to day enhancement of skills, techniques & continuous research have made this indigenous technique a success in Prosthetic field, thanks to our supporting professionals.</p>
      <h5>SILICONE PRODUCT RANGE</h5>
      <ul>
         <li>Finger Prosthesis</li>
         <li>Hand Prostheses</li>
         <li>Foot Prostheses</li>
         <li>Ear Prosthesis</li>
         <li>Partial Foot Prosthesis</li>
         <li>Nose Prosthesis</li>
         <li>Breast Prosthesis</li>
         <li>Thumb Prosthesis</li>
         <li>Partial hand Prosthesis</li>
         <li>Eye Prosthesis</li>
         <li>Wrist Disarticulation</li>
         <li>Custom Silicone liners</li>
         <li>Maxillofacial & Facial Prosthesis</li>
      </ul>
  </div>
</div>
</div>
);
}


export default Silicone;             














