import React from "react";
import slip from "../../Assets/Images/HIGH ANKLE BOOT.jpg";
import one from "../../Assets/08-06-2021/img-tp2.png";
import two from "../../Assets/08-06-2021/vcm50_05.jpg";
import three from "../../Assets/08-06-2021/5.png";
import four from "../../Assets/08-06-2021/4.png";
import five from "../../Assets/08-06-2021/insole.png";
import six from "../../Assets/08-06-2021/tplate2dyna.png";

import "./front.css";
const Front = () => {
  return (
    <div>
      <section class="home" id="home">
        <div class="home__container bd-container bd-grid">
          <div class="home__data">
            <h1 class="home-te">Welcome to RR ORTHO CARE</h1>
            <br />
            <h2 class="home__subtitle"> Solution to Ur Feet</h2>
          </div>
          <img src={slip} alt="" class="home__img" />
        </div>
      </section>
      {/* <!--========== ABOUT ==========--> */}
      {/* <section class="about section bd-container" id="about">
                <div class="about__container  bd-grid">
                    <div class="about__data">
                        <span class="section-subtitle about__initial">About us</span>
                        <h2 class="section-title about__initial">We cook the best <br/> tasty food</h2>
                        <p class="about__description">We cook the best food in the entire city, with excellent customer service, the best meals and at the best price, visit us.</p>
                        <a href="#" class="button">Explore history</a>
                    </div>

                    <img src={heel} alt="" class="about__img"/>
                </div>
            </section> */}

      {/* <!-- Gallery --> */}
      <div class="row">
        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
          <img src={one} class="w-100 shadow-1-strong rounded mb-4" alt="" />

          <img src={two} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0">
          <img src={three} class="w-100 shadow-1-strong rounded mb-4" alt="" />

          <img src={four} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0">
          <img src={five} class="w-100 shadow-1-strong rounded mb-4" alt="" />

          <img src={six} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
      </div>
      {/* <!-- Gallery --> */}
    </div>
  );
};

export default Front;
