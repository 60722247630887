import React, { Component } from 'react';
import "./contact.css";
import email from "./img/email.png";
import shape from "./img/shape.png";
import location from './img/location.png';
import phone from './img/phone.png';
const Contact=()=>{

    const inputs = document.querySelectorAll(".input");

// function focusFunc() {
//   let parent = this.parentNode;
//   parent.classList.add("focus");
// }

// function blurFunc() {
//   let parent = this.parentNode;
//   if (this.value == "") {
//     parent.classList.remove("focus");
//   }
// }

// inputs.forEach((input) => {
//   input.addEventListener("focus", focusFunc);
//   input.addEventListener("blur", blurFunc);
// });

        return (
            <div class="contain">
            <img src={shape} class="square" alt="" />
            <div class="form">
              <div class="contact-info">
                <h3 class="title">Let's get in touch</h3>
                <p class="text">
                  Enquire us 24x7 for any Product Details 
                </p>
      
                <div class="info">
                  <div class="information">
                  
                    <img src={location}class="icon" alt="" />
                    <p>No 142, E Main Rd, Pammal, Chennai, Tamil Nadu 600044,</p>
                  </div>
                  <div class="information">
                    <img src={email} class="icon" alt="" />
                    <p>rrleathers03@gmail.com</p>
                  </div>
                  <div class="information">
                    <img src={phone} class="icon" alt="" />
                    <p>9123510990</p>
                  </div>
                </div>
                <div class="social-media">
                  <p>Connect with us :</p>
                  <div class="social-icons">
                    <a href="#">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                    <i class="fas fa-phone"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a href="https://wa.me/919123510990" target="_blank">
                      
                      <i class="fab fa-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </div>
      
              <div class="contact-form">
                <form action="https://formsubmit.co/sethugopi4@gmail.com" method="POST">
                  <h3 class="title">Contact us</h3>
                  <div class="input-contain">
                    <input type="text" name="name" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Name</label>  
                  </div>
                  {/* <div class="input-contain">
                    <input type="tel" name="phone" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Phone</label>  
                  </div>

                  <div class="input-contain">
                    <input type="text" name="name" class="form__input input" />
                    <label for="" class="form__label">Username</label>
                    
                  </div>
                  <div class="input-contain">
                    <input type="email" name="email" class=" form__input input" placeholder="" />
                    <label for="" class="form__label">Email</label>
                  </div> */}
                  <div class="input-contain">
                    <input type="tel" name="Phone" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Phone</label>  
                  </div>


                  <div class="input-contain">
                    <input type="text"name="Email" class="form__input input" placeholder=" "/>
                    <label for="" class="form__label">Email</label>
                </div>


                  <div class="input-contain ">
                  <textarea type="text"name="Message" class="form__input input" placeholder=" "/>
                    <label for="" class="form__label">Write Your Message Here</label>
                  </div>
                  <input type="submit" value="Send" class="bt" />
                </form>
              </div>
            </div>
          </div>
      
        );
    }



export default Contact;