import React, { Component } from 'react';
import "./contact.css";


function Mail(){
return(
  <div class="container">
  <form action="https://formsubmit.co/sethugopi4@gmail.com" method="POST">
     <input type="text" name="name" required/>
     <input type="email" name="email" required/>
     <button type="submit">Send</button>
</form>


    <h1>FormSubmit Demo</h1>
    <form target="_blank" action="https://formsubmit.co/sethugopi4@gmail.com" method="POST">
        <div class="form-group">
            {/* <div class="form-row">
                <div class="col">
                    <input type="text" name="name" class="form-control" placeholder="Full Name" required/>
                </div>
                <div class="col">
                    <input type="email" name="email" class="form-control" placeholder="Email Address" required/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <textarea placeholder="Your Message" class="form-control" name="message" rows="10" required></textarea>
        </div> */}
         <div class="input-contain">
                    <input type="text" name="name" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Name</label>  
                  </div>
                  {/* <div class="input-contain">
                    <input type="tel" name="phone" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Phone</label>  
                  </div>

                  <div class="input-contain">
                    <input type="text" name="name" class="form__input input" />
                    <label for="" class="form__label">Username</label>
                    
                  </div>
                  <div class="input-contain">
                    <input type="email" name="email" class=" form__input input" placeholder="" />
                    <label for="" class="form__label">Email</label>
                  </div> */}
                  <div class="input-contain">
                    <input type="tel" name="phone" class="form__input input" placeholder=" " />
                    <label for=""class="form__label">Phone</label>  
                  </div>


                  <div class="input-contain">
                    <input type="text" class="form__input input" placeholder=" "/>
                    <label for="" class="form__label">Email</label>
                </div>


                  <div class="input-contain ">
                  <textarea type="text" class="form__input input" placeholder=" "/>
                    <label for="" class="form__label">Write Your Message Here</label>
                  </div>
                  </div>
        <button type="submit" class="btn btn-lg btn-dark btn-block">Submit Form</button>
    </form>
</div>
)
}
export default Mail;