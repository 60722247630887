import React from 'react';
import {BrowserRouter, Route, NavLink, Routes} from "react-router-dom";

import Aboutus from './Components/About/Aboutus';
import Contact from './Components/Contact/contact';

import Fcare from './Components/Footcare/Fcare';
import ToeSeparator from './Components/Footcare/Pages/ToeSeparator';
import MetartasalBar from './Components/Footcare/Pages/MetartasalBar';
import MedicalArchSupport from './Components/Footcare/Pages/MedicalArchSupport';
import Lateral from './Components/Footcare/Pages/Lateral';

import Home from  "./Components/Home/home"
import Special from './Components/Home/special';

import logo from "./Assets/Images/logo.jpeg"
// import Pages from './Components/Pages/pages'
import Footer from './Components/Footer/footer'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './App.css'
import './navcss.css' 
import Dropdown from './Components/Dropdown/Dropdown';


import Services from './Components/Services/Ourservices'

import Orthoses from './Components/Services/Pages/Orthoses/Orthoses'

import Silione from './Components/Services/Pages/Prothesis/Aesthetic(Silicone)Restoration/Silicon'
import TransFemorl from './Components/Services/Pages/Prothesis/LowerExtremityProsthesis/TransFemoral'
import TransTibil from './Components/Services/Pages/Prothesis/LowerExtremityProsthesis/TransTibil'
import Fitness from './Components/Services/Pages/Prothesis/ProsthesisForSpecialNeeds/Fitness'
import WaterProof from './Components/Services/Pages/Prothesis/ProsthesisForSpecialNeeds/WaterProof'
import Cosmetic from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/Cosmetic'
import Mechanical from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/Mechanical'
import MyoElectric from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/MyoElectric'
import Mail from './Components/Contact/contact2';
import CustomNade from './Components/Services/Pages/CustomMade';
import Mcp from './Components/Services/Pages/Mcr__Mcp';
import AnkleBoot from './Components/Services/Pages/AnkleBoot';
import Prefab from './Components/Services/Pages/Prefab';
import WoundCare from './Components/Services/Pages/WoundCare';
import All from './Components/Footcare/All.js';
import Mens from './Components/Products/Mens';
import Ladies from './Components/Products/Ladies';
import Kids from './Components/Products/Kids';

class App extends React.Component {
  render() {
  return (                                                                          
    <div>
    <BrowserRouter>
      <Dropdown/>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/special" element={<Special/>}/>
      <Route path="/about" element={<Aboutus/>}/>
      <Route path="/custom" element={<CustomNade/>}/>
      <Route path="/orthotics" element={<Orthoses/>}/>
      <Route path="/mail" element={<Mail/>}/>
      <Route path="/mcp" element={<Mcp/>}/>
      <Route path="/footcare" element={<Fcare/>}/>
      <Route path="/topseparator" element={<ToeSeparator/>}/>
      <Route path="/metartasalbar" element={<MetartasalBar/>}/>
      <Route path="/medicalarchsupport" element={<MedicalArchSupport/>}/>
      <Route path="/lateral" element={<Lateral/>}/>
      <Route path="/ankle" element={<AnkleBoot/>}/>
      <Route path="/male-products" element={<Mens/>}/>
      <Route path="/kids-products" element={<Kids/>}/>
      <Route path="/female-products" element={<Ladies/>}/>
      <Route path="/service" element={<Services/>}/>
      <Route path="/prefab" element={<Prefab/>}/>
      <Route path="/orthotics" element={<Orthoses/>}/>
      <Route path="/woundcare" element={<WoundCare/>}/>
      <Route path ="/silicone" element={<Silione/>}/>
      <Route path="/trans-femoral" element={<TransFemorl/>}/>
      <Route path="/trans-tibil" element={<TransTibil/>}/>
      <Route path="/fitness" element={<Fitness/>}/>
      <Route path="/waterproof" element={<WaterProof/>}/>
      <Route path="/cosmetic" element={<Cosmetic/>}/>
      <Route path="/mechanical" element={<Mechanical/>}/>
      <Route path="/myoelectric" element={<MyoElectric/>}/>
      <Route path="/All" element={<All/>}/>
      </Routes>
      <a href="https://wa.me/919123510990" class="whatsapp_float" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>
    <Footer/>
    </BrowserRouter>
    </div>
  );
}
}
export default App;