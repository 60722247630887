import React from 'react'

function Cosmetic() {
  return (
    <div>

    </div>
  )
}

export default Cosmetic
