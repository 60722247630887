import React from 'react'
import one from "../../Assets/MODELS/FEMALE/LOLITA.jpg";
import two from "../../Assets/MODELS/FEMALE/MAIDEN.jpg";
import three from "../../Assets/MODELS/FEMALE/QUEENIE.jpg";
import four from "../../Assets/MODELS/FEMALE/RAISIN.jpg";
import five from "../../Assets/MODELS/FEMALE/SILKY.jpg";
import six from "../../Assets/MODELS/FEMALE/V - STRAP.jpg";
import seven from "../../Assets/MODELS/FEMALE/VAAGAI.jpg";
function Ladies() {
    return (
        <>
         <h2 class="text-center mt-5">FootWare and Shoes For Women</h2>
    <div class="row mt-2">
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={one} alt=""/>
            <h5>Lolita</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={two} alt=""/>
            <h5>Maiden</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={three} alt=""/>
            <h5>Queenie</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={four} alt=""/>
            <h5>Raisin</h5>
        </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={five} alt=""/>
            <h5>Silky</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={six} alt=""/>
            <h5>V-Strap</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={seven} alt=""/>
            <h5>Vaagai</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={seven} alt=""/>
            <h5>Vaagai</h5>
        </div>
        </div>
    </div>
   
        </>
    )
}

export default Ladies
