import React from "react";
import bg from "../../../../../Assets/Images/Wallpaper1.jpg";
function MyoElectric() {
  return (
    <div classNameName="Pages-font-size">
      <h2 className="text-center">Myo Electric</h2>
      <div className="row container-fluid justify-content-center mt-3">
        <div className="col-sm-4 ">
          <img className="w-100 rounded mt-2" src={bg} alt="" />
          <img className="w-100 rounded mt-2" src={bg} alt="" />
          <img className="w-100 rounded mt-2" src={bg} alt="" />
        </div>
        <div className="col-sm-8  mt-2 mb-5"></div>
      </div>
    </div>
  );
}

export default MyoElectric;
