import React from 'react';
import bg from '../../../../Assets/Images/Wallpaper1.jpg';
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


export default function Orthoses() {
return(
<div classNameName="container Pages-font-size">
  <h1 className="mt-5 text-center">Orthotics</h1>
  <p>
    Orthotics, commonly called braces/callipers, are devices used to correct, support or replace musculoskeletal functions lost or impaired as a result of injury or congenital irregularity.
    They are also used for prevention of deformities. we use the latest in high tech, lightweight components incorporated into precision fitting orthoses to achieve the best possible fit available
    Orthotics begins with a one-on-one consultation with the clinician and includes evaluation, recommendations, treatment, education and rehabilitation.
    Our practitioner’s major role is to design and fit custom made orthotic devices. Our Team is dedicated to provide patients with the most effective orthotic solutions. We are continually working to advance the care of orthotics through research and education.
    Our Orthotic practitioner creates a range of precise, custom fit devices from complex bracing systems to spinal orthosis, neck orthosis, diabetic shoes, cranial helmets etc.
    Treatments and services encompass pediatrics, adults, cranium, spine, upper and lower extremity, etc.
  </p>
        <img className="w-100 rounded mt-2" src={bg} alt=""/>
        <h1>Scoliosis Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
                Used to provide corrective forces to the lumber and thoracic spine, this orthosis comes in many different styles.
                Most common are the 23 hour Boston Brace (worn for 23 hours a day) or the nocturnal Providence Brace (worn during the night).
            </p>
            <h5>Indications for a Scoliosis Orthosis may include:</h5>
            <ol>
                <li>Infantile, Juvenile, and Adolescent Idiopathic Scoliosis</li>
                <li>Neuromuscular Scoliotic Curves</li>
                <li>Congenital Scoliotic Curves</li>
            </ol>
        </div>
        </div>
        <h1>Lumbosacral Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            LSOs stabilize the lumbosacral spine through intra-abdominal pressure and provide pain relief by reducing pressure on individual vertebrae.
            </p>
            <h5>Indications for a Lumbosacral Orthosis may include:</h5>
            <ol>
                <li>Low Back Pain</li>
                <li>Herniated Disc</li>
                <li>Post Operative</li>
                <li>Degenerative Disc Disease</li>
                <li>Lumbar Fracture</li>
                <li>Osteoarthritis</li>
                <li>Stenosis</li>
            </ol>
        </div>
        </div>
        <h1>Hyperextension Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
                This orthosis use three points of pressure to stabilize the thoracic spine.
            </p>
            <h5>Indications for a Hyperextension Orthosis may include:v</h5>
            <ol>
                <li>Anterior Vertebral Body Fracture</li>
                <li>Kyphosis (abnormal curvature of the spine) as a result of Osteoporosis</li>
                <li>Scheumann’s Kyphosis</li>
                <li>Degenerative Disc Disease</li>
            </ol>
        </div>
        </div>
        <h1>Thoracolumbar Sacral Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
               TLSOs are used to provide rigid support and stabilize the spine.
            </p>
            <h5>Indications for a TLSO may include:</h5>
            <ol>
                <li>Post Operative</li>
                <li>Degenerative Disc disease</li>
                <li>Spinal Fracture</li>
                <li>Spondylolisthesis</li>
                <li>Spondylosis</li>
                <li>Stenosis</li>
                <li>Disc Herniation</li>
                <li>Osteoarthritis</li>
            </ol>
        </div>
        </div>
        <h1>HALO Systems</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Designed for maximum control of the cervical spine, Halos provide maximum immobilization of the head in relation to the thoracic spine.
            </p>
            <h5>Indications for a HALO System may include:</h5>
            <ol>
               <li>Cervical Spine Trauma</li>
               <li>Hangman’s Fracture</li>
               <li>Severe Instability of the Cervical Spine</li>
               <li>Post Operative Stabilization</li>
            </ol>
        </div>
        </div>
        <h1>Cervical Thoracic Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Cervical Thoracic orthoses are designed to provide support and immobilization of the cervical and upper thoracic spine as well as provide anterior/posterior, medial/lateral and rotational control.
            </p>
            <h5>Indications for a Cervical Thoracic Orthosis may include:</h5>
            <ol>
                <li>Cervical and Upper Thoracic Spine Trauma</li>
                <li>Degenerative Disc Disease</li>
                <li>Post Operative</li>
                <li>Cervical and Upper Thoracic Spine Instability</li>
            </ol>
        </div>
        </div>
        <h1>Soft And Rigid Cervical Collars</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            These collars are used to stabilize the cervical spine and are made from flexible foam, for minimal support, and thermoplastic material, for maximum support.
            </p>
            <h5>Indications for Soft and Rigid Cervical Collars may include:</h5>
            <ol>
               <li>Cervical Trauma</li>
               <li>Degenerative Disc Disease</li>
               <li>Post Operative</li>
               <li>Cervical Instability</li>
            </ol>
        </div>
        </div>
        <h1>Wrist Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Wrist orthoses support, protect and prevent deformities of the wrist, while Wrist Hand orthoses support both the wrist and hand.
            </p>
            <h5>Indications for a Wrist Orthosis or a Wrist Hand Orthosis Include:</h5>
            <ol>
               <li>Carpal Tunnel Syndrome</li>
               <li>Cerebrovascular Accident (CVA)</li>
               <li>Wrist and/or Hand Contractures</li>
               <li>When a Functional Hand Position is Indicated</li>
            </ol>
        </div>
        </div>
        <h1>Fracture Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Commonly referred to as “removable casts”, fracture orthoses protect and prevent deformity in the case of a non-displaced fracture.
            Minimal movement of the fractured area actually promotes and stimulates bone growth resulting in less healing time.
            </p>
            <h5>Indications for an Upper Extremity Fracture Orthosis may include:</h5>
            <ol>
                <li>Fracture of the Humerus</li>
                <li>Fracture of the Ulna</li>
                <li>Fracture of the Radius</li>
            </ol>
        </div>
        </div>
        <h1>Elbow Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Designed to support, protect and prevent deformity of the elbow, this orthosis can be custom made or prefabricated depending on the amount of control needed. Your orthotist will work with your physician to determine the most appropriate orthosis for you.</p>
            <h5>Indications for an elbow Orthosis may include:</h5>
            <ol>
                <li>Post Operative</li>
                <li>Olecranon (prominent bone of the elbow joint) Fracture</li>
                <li>Medial or Lateral Epicondylitis</li>
                <li>Elbow Contracture</li>
                <li>Trauma</li>
            </ol>
        </div>
        </div>
        <h1>Shoulder Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Designed to support, protect and prevent deformity of the shoulder, this orthosis is commonly used post operatively for immobilization of the ball and socket joint.
            </p>
            <h5>Indications for a Shoulder Orthosis may include:</h5>
            <ol>
                <li>Tear of the Shoulder Capsule</li>
                <li>Rotator Cuff Repair</li>
                <li>Post Operative Use</li>
                <li>Chronic AC Joint Dislocation</li>
            </ol>
        </div>
        </div>
        <h1>MyoelectricOrthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            A custom-fit device, this orthosis uses Functional Electrical Stimulation (FES) to activate muscle groups in the forearm to produce functional movement patterns in the hand.            
            </p>
            <h5>Key Benefits include:</h5>
            <ol>
                <li>Neuromuscular re-education</li>
                <li>Prevention/retardation of atrophy</li>
                <li>Reduction of muscle spasm</li>
                <li>Maintenance/increase of joint range of motion</li>
                <li>Increase in local blood circulation</li>
                <li>Providing hand function</li>
            </ol>
            <h5>Indications for a Myoelectric Upper Extremity Orthosis may include:</h5>
            <ol>
                <li>Cerebral Vascular Accident or Stroke</li>
                <li>Spinal Cord Injury</li>
            </ol>
        </div>
        </div>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Finger orthoses are used to support, protect and prevent deformity of the fingers.
            </p>
            <h5>Indications for a Finger Orthosis include:</h5>
            <ol>
                <li>Boutinniere Deformity (the joint nearest the knuckle is bent toward the palm)</li>
                <li>Mallet Finger (extensor tendon injury)</li>
                <li>Dislocation</li>
                <li>Swan Neck Deformity (joint nearest the finger tip is bent toward the palm and the joint nearest to palm is bent away from it)</li>
                <li>Trigger Finger</li>
                <li>DeQuervain’s Tenosynovitis (inflammation of the tendons in the thumb)</li>
                <li>Rheumatoid Arthritis</li>
                <li>Fracture</li>
            </ol>
        </div>
        </div>
        <h1>Ankle Foot Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            AFO’s protect, support and prevent further deformity or damage to the ankle and foot.
            They can be custom made from a mold of the patient’s foot, or prefabricated, depending upon the control needed.
            </p>
            <h5>Indications for an Ankle Foot Orthosis include:</h5>
            <ol>
                <li>Drop Foot</li>
                <li>CVA/Stroke</li>
                <li>Arthritis</li>
                <li>Posterior Tibial Tendon Dysfunction</li>
                <li>Ankle Instability</li>
                <li>Paralysis</li>
                <li>Ankle Fusion</li>
                <li>Multiple Sclerosi</li>
            </ol>
        </div>
        </div>
        <h1>Knee Orthoses – Prefab and Custom</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Designed to support, protect and prevent deformity of the knee joint, this orthotic can be prefabricated or custom molded.
            </p>
            <h5>Indications for a Knee Orthosis include:</h5>
            <ol>
                <li>Osteoarthritis</li>
                <li>Knee Instability</li>
                <li>ACL/PCL Tear or Repair</li>
                <li>Varus (Inward Angle) or Valgus Outward Angle) Deformities</li>
                <li>MCL/LCL Insufficiencies</li>
                <li>Post Operative</li>
                <li>Patella Dislocation</li>
                <li>Multiple Sclerosis</li>
            </ol>
        </div>
        </div>
        <h1>Fracture Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            Commonly referred to as “removable casts”, these devices are designed to protect and prevent deformity from a non-displaced (simple) fracture.
            Minimal movement of the fractured area stimulates bone growth resulting in faster healing.
            </p>
            <h5>Indications for a Fracture Orthosis include:</h5>
            <ol>
                <li>Fracture of the Tibia</li>
                <li>Fracture of the Femur</li>
            </ol>
        </div>
        </div>
        <h1>Patella Tendon Bearing Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            A PTB shifts weight bearing from the ankle/foot to the patella tendon and other pressure areas.
            </p>
            <h5>Indications for a PTB Orthosis include:</h5>
            <ol>
                <li>Ankle Fracture</li>
                <li>Plantar Surface Heel Ulcer</li>
                <li>Distal Tibial Fracture</li>
            </ol>
        </div>
        </div>
        <h1>Knee Ankle Foot Orthoses</h1>
       <div className="row">
        <div className="col-sm-8  mt-2 mb-5">
            <p>
            KFAOs support, protect and prevent deformity of the knee, ankle and foot. They are usually custom made from a mold of the patient.
            Several variations can be incorporated into a KAFO including range of motion knee joints, free motion ankle joints, varus/valgus correction straps and thigh lacers.
            </p>
            <h5>Indications for a Fracture Orthosis include:</h5>
            <ol>
                <li>Instability of the Knee and Ankle</li>
                <li>CVA or Stroke</li>
                <li>Paralysis Knee Replacement</li>
                <li>Weak quadriceps</li>
                <li>Genu Recurvatum (Knee bends backwards)</li>
                <li>Spina Bifida</li>
                <li>Post Polio</li>
                <li>Cerebral Palsy</li>
            </ol>
        </div>
        </div>
      </div>
  );
}