import React from "react";
import bg from "../../../Assets/services/Picture2.png";
import bg1 from "../../../Assets/services/Picture3.png";

const Mcp = () => {
  return (
    <div className="row container-fluid mt-3 Pages-font-size">
      <div className="col-sm-4 justify-content-center">
        <img className="w-100 rounded mt-2" src={bg} alt="" />
        <img className="w-100 rounded mt-2" src={bg1} alt="" />
      </div>
      <div className="col-sm-8  mt-2 mb-5">
        <h1> MCR AND MCP FOOTWEAR </h1>
        <p>
          MCR and MCP footwear’s are made of premium quality Micro Cellular
          Rubber and Micro Cellular Polymer, which will help diabetic patients
          to prevent from diabetic neuropathy, peripheral vascular disease and
          other diabetic foot problems. MCR Slippers and Shoes are ideal for
          Heel Pain, Back Pain, Knee Pain and Leg Pain.
          <br />
          Our products are scientifically designed, Unique, Soft, Flexible and
          provides extra comfort for your feet. Benefits of our product will
          provides to diabetes patients, including many of who suffer from poor
          blood circulation in their feet.
        </p>
        <h4>DIABETIC FOOTWEAR, ORTHOPETIC SLIPPERS HEALTH BENEFITS</h4>
        <h4>Benefits of MCR Slippers / MCP Slippers/ MCR Footwear</h4>
        <p>
          Legs are most important part of Body, which is holding entire body
          weight.
        </p>
        <h3>
          Benefits of MCR Chappals / MCP Slippers/ MCR Footwear and MCR based
          Diabetic Shoes:
        </h3>
        <p>
          Generally Micro Cellular Rubber Footwear or MCR Footwear used to heal
          Diabetic foot Problems, Foot Pain, Arch Pain and Back pain. Also it
          will provide following health benefits
        </p>
        <ol>
          <li>
            Microcellular Rubber Footwear or MCR Footwear used to cure Diabetic
            Problems, Foot Pain, Arch Pain and Back pain
          </li>
          <li>Flat Foot causes Serious health problem</li>
          <li>MCR Slippers and Insoles absorb shocks from eternal forces</li>
          <li>MCR Based Arch Insoles prevent the friction</li>
          <li>
            Arch Support footwear or Insole can prevent the Flat Foot Problems
          </li>
          <li>Plantar Fasciitis (High Arch) Problem</li>
          <li>Bunlon (Narrow Toe) problem</li>
          <li>+Morton’s Neuroma (Leg Fingers Pain) Problem</li>
          <li>Hallux Rigidus (Leg Tamp Finger Pain) Problem</li>
          <li>Lower Back Pain</li>
          <li>Corns (hard Skin Over Leg Toe Area)</li>
          <li>Calluses (hard Skin bottom of the feet area)</li>
          <li>Diabetic Foot Care</li>
          <li>Heel Pain</li>
        </ol>
      </div>
    </div>
  );
};

export default Mcp;
