import React from "react";

import bg from "../../../../../Assets/Images/Wallpaper1.jpg";
function Fitness() {
  return (
    <div classNameName="Pages-font-size">
      <h2 className="text-center">Water Proof Prosthesis</h2>
      <div className="row container-fluid justify-content-center mt-3">
        <div className="col-sm-4 ">
          <img className="w-100 rounded mt-2" src={bg} alt="" />
          <img className="w-100 rounded mt-2" src={bg} alt="" />
          <img className="w-100 rounded mt-2" src={bg} alt="" />
        </div>
        <div className="col-sm-8  mt-2 mb-5">
          <p>
            Functionality is superior to aesthetics.
            <br />
            Widely accepted for running ,jumping or any fitness activities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Fitness;
